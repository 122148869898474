<template>
  <b-container fluid="xs">
    <vue-element-loading :active="isloading" spinner="ring" background-color="rgba(255,255,255,0.6)" size="400" color="#F90"/>
    <div v-show="items.length">
    <b-table-simple hover small caption-top responsive="xs">
      <b-thead head-variant="light">
        <b-tr>
          <b-th>用户手机</b-th>
          <b-th class="d-none d-sm-table-cell">微信昵称头像</b-th>
          <b-th class="d-none d-sm-table-cell">领取日期</b-th>
          <b-th>类型</b-th>
          <b-th>面额</b-th>
          <b-th>数目</b-th>
          <b-th>使用详情</b-th>
        </b-tr>        
      </b-thead>
      <b-tbody>
        <b-tr v-for="(item, index) in items" :key="index">
          <b-td><a href="#" @click="setmobile(item.mobile)">{{item.mobile}}</a></b-td>
          <b-td class="d-none d-sm-table-cell">{{item.nickname}}</b-td>
          <b-td class="d-none d-sm-table-cell">{{item.credate}}</b-td>
          <b-td>{{item.typestr}}/{{item.billstart}}</b-td>
          <b-td>{{item.facemoney}}</b-td>
          <b-td>{{item.remain}}/{{item.mounts}}</b-td>
          <b-td>
            <b-button size="sm" variant="info" @click="showdetail(item)" :disabled="item.sessions.length==0">点击查询</b-button>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    </div>
    <div v-show="items.length==0" class="pl-5 pr-5">
      <b-card class="xnshadow" header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h4 class="mb-0"><b-icon icon="gem"></b-icon> 优惠券使用情况</h4>
        </template>
        <b-card-text>
          <p>
            没有发现优惠券发放及使用记录<br/><br/>
            <b-form inline class="mt-4"><b-alert :show="admnodft">{{$t('message.cfmdefault')}}</b-alert></b-form>
           </p>
        </b-card-text>
      </b-card>
    </div>
    <b-form inline class="mt-4 ml-4">
        <b-button-group class="mr-4 mb-4">
            <b-button variant="success" @click="fetchprev" :disabled="pageid==0">{{$t('message.btn_prevpage')}}</b-button>
            <b-button v-if="pageid>0" variant="outline-success" :disabled="true" >{{pageid+1}}</b-button>
            <b-button variant="success" @click="fetchNext" :disabled="pageid==newpagestart.length">{{$t('message.btn_nextpage')}}</b-button>
        </b-button-group>
        <b-input-group class="mr-4 mb-4">
            <b-form-input :placeholder="$t('message.entermobile')" v-model="searchmobile" @keyup.enter="search"></b-form-input>
            <b-input-group-append>
                <b-button variant="info" @click="search">{{$t('message.btn_search')}}</b-button>
            </b-input-group-append>
            </b-input-group>
        <b-button-group class="mb-4 d-none d-lg-block" style="width:580px;">
        <b-input-group>
            <b-form-datepicker placeholder="起始日期(包含)" menu-class="w-100" calendar-width="100%" locale="zh" start-weekday="1" hide-header="true" label-help="" weekday-header-format="narrow" id="datepicker-start" v-model="datstart"></b-form-datepicker>
            <b-form-datepicker placeholder="结束日期(不含)" menu-class="w-100" calendar-width="100%" locale="zh" start-weekday="1" hide-header="true" label-help="" weekday-header-format="narrow" id="datepicker-end"  v-model="datend"></b-form-datepicker>
            <b-input-group-append>
                <b-button variant="info" @click="download" :disabled="clicked">
                    <span v-if="clicked"><b-icon variant="danger" icon="circle-fill" animation="throb" scale="4" font-scale="1" class="mr-2"></b-icon>下载</span>
                    <span v-else><b-icon icon="cloud-download" scale="1" font-scale="1" class="mr-2"></b-icon>下载</span>
                </b-button>
            </b-input-group-append>
        </b-input-group>
        </b-button-group>
    </b-form>
    <b-modal v-model="modalshow" no-close-on-backdrop no-close-on-esc hide-header ok-only :ok-title="$t('message.btn_ok')"><span v-html="modalmsg"></span></b-modal>
  </b-container>
</template>

<script>
  import { QRSITE } from '@/config'
  export default {
    name: 'svcbilluse',
    mounted() {
      this.fetchData();
    },
    data() {
      return {
        isloading: false,
        modalshow:false,
        modalmsg: '',
        admnodft:false,
        searchmobile:'',
        items: [],
        pageid:0,
        datstart:'',
        datend:'',
        clicked:false,
        timeoutcnts:0,
        genrated:false,
        newpagestart: []
      };
    },
    methods: {
      search() {
          let mo = parseInt(this.searchmobile);
          if (this.searchmobile.length > 0) {
            if (isNaN(mo)) {
                this.modalmsg = '无效的手机号，请输入数字！';
                this.modalshow = true;
                return;
            } else if (this.searchmobile.length != 11) {
                this.modalmsg = '无效的手机号，只允许11位数字！';
                this.modalshow = true;
                return;
            }
          }
          this.pageid = 0;
          this.newpagestart = [];
          this.fetchData();
      },
      setmobile(mo) {
          if (this.searchmobile==mo) {
            this.searchmobile = '';
          } else {
            this.searchmobile = mo;
          }
          this.pageid = 0;
          this.newpagestart = [];
          this.fetchData();
      },
      fetchprev() {
        this.pageid = this.pageid-1;
        this.fetchData();
      },
      fetchPage(page) {
        this.pageid = page;
        this.fetchData();
      },
      fetchNext() {
        this.pageid = this.pageid+1;
        this.fetchData();
      },
      fetchData() {
        this.isloading = true;
        let user = JSON.parse(localStorage.getItem('user'));
        let dftapp = localStorage.getItem('dftapp');
        if (user.isadmin && !dftapp) {
          this.admnodft = true;
        } else {
          this.admnodft = false;
        }
        let w = window.innerWidth;
        let h = window.innerHeight;
        let token = localStorage.getItem('token');
        let theapi = '/svcbills?tm='+new Date().getTime()+'&token='+token+'&pagesize='+this.$pagesize+'&w='+w+'&h='+h;
        if (this.pageid>0 && this.newpagestart[this.pageid-1]) {
          theapi = theapi + '&last='+encodeURIComponent(JSON.stringify(this.newpagestart[this.pageid-1]));
        }
        if (this.searchmobile) {
          theapi = theapi + '&mobile='+this.searchmobile
        }
        this.axios.get(theapi).then(axresp => {
          this.isloading = false;
          if (axresp.status==200) {
            let axdata = axresp.data;
            this.items = axdata.Items;
            if (axdata.LastEvaluatedKey) {
              if (this.newpagestart.length<=this.pageid) {
                this.newpagestart.push(axdata.LastEvaluatedKey);
              } else {
                this.newpagestart[this.pageid] = axdata.LastEvaluatedKey;
              }
            }
          } else {
            this.items = [];
          }
        });
      },
      async download(){
          if (this.datend=='') this.datend = new Date().format('yyyy-MM-dd');
          if (this.datstart=='') {
            this.modalmsg = '开始日期不能为空；<br/>不填结束日期表示截止今日0点；<br/>日期跨度不能大于31天；<br/>查询耗时较长，请耐心等待；';
            this.modalshow = true;
          } else {
            let startDate = new Date(Date.parse(this.datstart.replace(/-/g,'/')));
            let endDate = new Date(Date.parse(this.datend.replace(/-/g,'/')));
            let diff = (endDate.getTime()-startDate.getTime())/86400000;
            if (diff<0) {
                this.modalmsg = '开始日期不能晚于结束日期';
                this.modalshow = true;
            } else if (diff>31){
                this.modalmsg = '下载日期跨度不能超过31天';
                this.modalshow = true;
            } else {
                this.clicked = true;
                let token = localStorage.getItem('token');
                let nowdate = new Date().format('yyyy-MM-dd');
                let resulturl = QRSITE+'/qr/excel6/'+token+'_'+this.datstart+'_'+this.datend+'_'+nowdate+'.xlsx';
                let hdresp = undefined;
                try{
                    hdresp = await this.axios.head(resulturl);
                }catch(e){
                    if (e) hdresp = undefined;
                }
                if (hdresp && hdresp.status==200) {
                    this.clicked = false;
                    this.genrated = false;
                    window.location.href = resulturl;
                } else {
                    if (this.genrated==false) {
                        let today = new Date().format('yyyy-MM-dd');
                        let downdone = localStorage.getItem('downdone6');
                        if (downdone && downdone==today) {
                            this.modalmsg = '该功能每天限制运行一次，你已经运行过了，请明天再来。';
                            this.modalshow = true;
                            this.clicked = false;
                        } else {
                            var msg = '统计下载每天限运行一次，本次运行后今天将无法再次启动，建议起止日期设置为每月1日，你真的要现在运行吗？';
                            if (confirm(msg)) {
                                localStorage.setItem('downdone6', today);
                                this.genrated = true;
                                let theapi = '/svcbilldown?tm='+new Date().getTime()+'&token='+token+'&datstart='+this.datstart+'&datend='+this.datend;
                                this.axios.get(theapi).then(() => {});
                                this.modalmsg = '查询一个月需要10分钟或更长时间；<br/>本功能每日限制执行一次；<br/>建议每次查询1日到下月1日；<br/>查询时间为选择日的0点，所以不含结束当天；<br/>查询的时间间隔最大为31天；<br/>本查询比较费时，请耐心等待；<br/>请不要频繁反复使用本功能；<br/>下载后请妥善保存文件；<br/>如果长时间没有结果，请刷新页面重新查询；<br/>结果返回前光标会一直闪；';
                                this.modalshow = true;
                            } else {
                                this.clicked = false;
                            }
                        }
                    }
                    if (this.clicked) {
                        this.timeoutcnts++
                        if (this.timeoutcnts<3) {
                            setTimeout(this.download, 10000);
                        } else {
                            this.clicked = false;
                            this.modalmsg = '后台正在进行数据统计汇总<br/>10分钟后选择相同的日期下载数据';
                            this.modalshow = true;
                        }
                    }
                }
            }
          }
      },
      showdetail(itm) {
        if (itm.sessions.length>0) {
          let token = localStorage.getItem('token');
          let theapi = '/svcbilluse?tm='+new Date().getTime()+'&token='+token+'&openid='+itm.openid+'&sessions='+itm.sessions.join(',');
          this.axios.get(theapi).then(axresp => {
            if (axresp.status==200) {
              let arrs = axresp.data;
              this.modalmsg = '<table cellpadding="8"><thead>';
              this.modalmsg += '<tr><th>充电日期</th><th>启动时间</th><th>充电站</th><th>电桩#端口</th><th>账单¥</th><th>扣款¥</th></tr>';
              this.modalmsg += '</thead><tbody>';
              for (let i=arrs.length-1;i>=0;i--) {
                this.modalmsg += '<tr>';
                this.modalmsg += '<td>'+arrs[i].hdate+'</td>';
                this.modalmsg += '<td>'+arrs[i].htime+'</td>';
                this.modalmsg += '<td>'+arrs[i].siteid+'</td>';
                this.modalmsg += '<td>'+arrs[i].chargerid+'#'+arrs[i].portid+'</td>';
                this.modalmsg += '<td>'+arrs[i].billmoney+'</td>';
                this.modalmsg += '<td>'+arrs[i].paymoney+'</td>';
                this.modalmsg += '</tr>';
              }
              this.modalmsg += '</tbody></table>';
              this.modalshow = true;
            }
          });
        }
      },
    }
  }
</script>
